import { useTranslation } from "react-i18next";
import Button from "../../../components/Button";
import "./index.sass";

interface ISection {
  handlePlay: () => void;
  handlePlayAsAGuest: () => void;
}

const Section = ({ handlePlay, handlePlayAsAGuest }: ISection) => {
  const { i18n } = useTranslation();

  const moreItems = [
    {
      title: i18n.t("homepage.seccion_6.item_1.title"),
      description: i18n.t("homepage.seccion_6.item_1.description"),
      image: "/images/more_1.png",
    },
    {
      title: i18n.t("homepage.seccion_6.item_2.title"),
      description: i18n.t("homepage.seccion_6.item_2.description"),
      image: "/images/more_2.png",
    },
    {
      title: i18n.t("homepage.seccion_6.item_3.title"),
      description: i18n.t("homepage.seccion_6.item_3.description"),
      image: "/images/more_3.png",
    },
    {
      title: i18n.t("homepage.seccion_6.item_4.title"),
      description: i18n.t("homepage.seccion_6.item_4.description"),
      image: "/images/more_4.png",
    },
  ];

  return (
    <div className="SectionFour">
      <div className="body">
        <div className="subbody">
          <div className="global-label">{i18n.t("homepage.seccion_4.label")}</div>
          <div className="global-title">{i18n.t("homepage.seccion_4.title")}</div>
          <div className="global-subtitle">{i18n.t("homepage.seccion_4.subtitle")}</div>
        </div>
        <div className="margin-subbody2"></div>
        <div className="subbody subbody2">
          <div className="global-label">{i18n.t("homepage.seccion_5.label")}</div>
          <div className="global-title">{i18n.t("homepage.seccion_5.title")}</div>
          <div className="global-subtitle">{i18n.t("homepage.seccion_5.subtitle")}</div>
        </div>
        <div className="margin-subbody3"></div>
        <div className="subbody subbody3">
          <div className="global-title">{i18n.t("homepage.seccion_6.title")}</div>
        </div>
        <div className="subbody">
          <div className="items-more">
            {moreItems.map((item, index) => (
              <div key={index} className="more">
                <img src={item.image} alt="" />
                <div className="more-title">{item.title}</div>
                <div className={`more-description des${index}`}>{item.description}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="margin-subbody4"></div>
        <div className="subbody subbody5">
          <div className="global-title">{i18n.t("homepage.seccion_7.title")}</div>
          <div className="global-subtitle line-height-subtitle">{i18n.t("homepage.seccion_7.subtitle")}</div>
          <div className="btns">
            <Button onClick={handlePlay}>{i18n.t("homepage.seccion_7.play_btn")}</Button>
            <Button onClick={handlePlayAsAGuest} className="secondary">
              {i18n.t("homepage.seccion_7.play_as_guest_btn")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section;
