import { useTranslation } from "react-i18next";
import "./index.sass";

const Section = () => {
  const { i18n } = useTranslation();

  return (
    <div className="SectionWinAndLevelUp">
      <div className="body">
        <div className="subbody">
          <div className="global-label">{i18n.t("homepage.seccion_4.label")}</div>
          <div className="global-title">{i18n.t("homepage.seccion_4.title")}</div>
          <div className="global-subtitle">{i18n.t("homepage.seccion_4.subtitle")}</div>
        </div>
        <img className="img-legends" src="/mobile/level_up.png" alt="" />
      </div>
    </div>
  );
};

export default Section;
