import "./index.sass";

interface IButton {
  children: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: () => void;
  className?: string;
}
const Button = ({ children, style = {}, onClick, className = "" }: IButton) => {
  return (
    <div className={`Button ${className}`} style={style} onClick={onClick}>
      {children}
    </div>
  );
};

export default Button;
