import { useTranslation } from "react-i18next";
import "./index.sass";
import Button from "../../../components/Button";
import { useEffect, useState } from "react";

interface IHome {
  handlePlay: () => void;
  handlePlayAsAGuest: () => void;
}

const Home = ({ handlePlay, handlePlayAsAGuest }: IHome) => {
  const { i18n } = useTranslation();

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="home">
      <div>
        <img
          style={{ position: "absolute", width: "100%", left: 0, top: 0, objectFit: "cover", height: "100%" }}
          src="/website/section_1.png"
          alt=""
        />
        <img className="cards-img" src={width > 780 ? "/mobile/cards_mob3.png" : "/mobile/cards_mob2.png"} alt="" />
        <div className="home-box">
          <div className="title">{i18n.t("homepage.seccion_1.title")}</div>
          <div className="subtitle">{i18n.t("homepage.seccion_1.subtitle")}</div>
          <div className="description">{i18n.t("homepage.seccion_1.description")}</div>
          <div className="btns_group">
            <Button onClick={handlePlay}>{i18n.t("homepage.seccion_1.play_btn")}</Button>
            <Button onClick={handlePlayAsAGuest} className="secondary">
              {i18n.t("homepage.seccion_1.play_as_guest_btn")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
