import { useTranslation } from "react-i18next";
import "./index.sass";
import "./index.css";

const Section = () => {
  const { i18n } = useTranslation();

  const moreItems = [
    {
      title: i18n.t("homepage.seccion_6.item_1.title"),
      description: i18n.t("homepage.seccion_6.item_1.description"),
      image: "/images/more_1.png",
    },
    {
      title: i18n.t("homepage.seccion_6.item_2.title"),
      description: i18n.t("homepage.seccion_6.item_2.description"),
      image: "/images/more_2.png",
    },
    {
      title: i18n.t("homepage.seccion_6.item_3.title"),
      description: i18n.t("homepage.seccion_6.item_3.description"),
      image: "/images/more_3.png",
    },
    {
      title: i18n.t("homepage.seccion_6.item_4.title"),
      description: i18n.t("homepage.seccion_6.item_4.description"),
      image: "/images/more_4.png",
    },
  ];

  return (
    <div className="discover_more">
      <div className="global-title" style={{ color: "#004330" }}>
        {i18n.t("homepage.seccion_6.title")}
      </div>
      <div className="items-more">
        {moreItems.map((item, index) => (
          <div key={index} className="more">
            <img src={item.image} alt="" />
            <div className="more-title">{item.title}</div>
            <div className={`more-description`}>{item.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Section;
