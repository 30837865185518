import { useEffect, useState } from "react";
import { awsConfig } from "../../auth/config";
import { Amplify } from "aws-amplify";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";
import { sessionStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";
import TelemetryHelper from "../../utils/TelemetryHelper";
import { getCurrentUser } from "@aws-amplify/auth";

import "../../auth/amplify.css";
import "./index.sass";

cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);

interface LoginProps {
  setShowLoginPage: (value: boolean) => void;
  showLoginPage?: boolean;
  setLoading: (value: boolean) => void;
  setUnity: (value: boolean) => void;
  logout?: boolean;
}

const Login = ({ setShowLoginPage, showLoginPage = false, setLoading, setUnity, logout = false }: LoginProps) => {
  useEffect(() => {
    Amplify.configure(awsConfig);
  }, []);

  useEffect(() => {
    if (showLoginPage) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showLoginPage]);

  return (
    <Authenticator.Provider>
      <LoginPageContent
        setShowLoginPage={setShowLoginPage}
        showLoginPage={showLoginPage}
        setLoading={setLoading}
        setUnity={setUnity}
        logout={logout}
      />
    </Authenticator.Provider>
  );
};

const LoginPageContent = ({ setShowLoginPage, showLoginPage, setLoading, setUnity, logout }: LoginProps) => {
  const [token, setToken] = useState<string | null>(null);

  const { user, signOut, authStatus } = useAuthenticator((context) => [context.user]);
  const [isActive, setIsActive] = useState("visible");
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [marginBotton, setMarginBotton] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
      if (isActive === "hidden") {
        if (window.innerHeight > 845) setMarginBotton(0);
        else setMarginBotton(845 - window.innerHeight > 209 ? 209 : 845 - window.innerHeight + 68);
      } else {
        if (window.innerHeight > 716) setMarginBotton(0);
        else setMarginBotton(716 - window.innerHeight > 80 ? 80 : 716 - window.innerHeight + 68);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [isActive]);

  useEffect(() => {
    const updateIdSuffix = () => {
      const targetElement = document.querySelector('[role=tabpanel][id^="amplify-id-"]');

      if (targetElement) {
        const id = targetElement.getAttribute("id");
        if (id) setIsActive(id.replace("amplify-id-", "").includes("panel-signIn") ? "visible" : "hidden");
      } else setIsActive("hidden");
    };

    updateIdSuffix();

    const observer = new MutationObserver(() => {
      updateIdSuffix();
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ["id"],
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const checkLogin = async () => {
      try {
        const currentUser = await getCurrentUser();
        if (currentUser && showLoginPage) {
          TelemetryHelper.event({ category: "Homepage", action: "Redirection", label: "Login Redirect" });

          setShowLoginPage(false);
          setUnity(true);
          setLoading(true);
        }
      } catch (error) {}
    };
    checkLogin();
  }, [user, setShowLoginPage, setUnity, setLoading, showLoginPage]);

  useEffect(() => {
    const fetchToken = async () => {
      const data = await cognitoUserPoolsTokenProvider.getTokens();
      if (data?.idToken?.toString()) setToken(data?.idToken?.toString());
    };
    fetchToken();
  }, [showLoginPage]);

  useEffect(() => {
    if (logout) signOut();
  }, [logout, signOut]);

  useEffect(() => {
    signOut();
    document.cookie = "CognitoIdentityServiceProvider=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.clear();
    sessionStorage.clear();
  }, []);

  return (
    <>
      {!user && showLoginPage && (
        <div
          className="login-page"
          style={{ backgroundImage: `url(/images/bg_sign_up.jpg)`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
        >
          <div className="header">
            <img src="/images/logo.png" alt="logo" className="logo" />
          </div>
          <div style={{ position: "relative", maxWidth: "460px", margin: "0 auto", marginBottom: `${marginBotton}px` }}>
            <Authenticator loginMechanisms={["email"]} signUpAttributes={["preferred_username"]}>
              {() => (
                <div style={{ textAlign: "center", marginTop: "40px", fontFamily: "Rubik", fontWeight: "bold", fontSize: "30px" }}>
                  Loading
                </div>
              )}
            </Authenticator>
            <button
              onClick={() => setShowLoginPage(false)}
              className={`amplify-button amplify-field-group__control amplify-button--primary my-own-button ${isActive}`}
              type="submit"
            >
              Back
            </button>
          </div>
          <div className="footer-login">
            <div>
              <img src="images/footer_logo.png" alt="" style={{ width: "100%", maxWidth: "172px" }} />
            </div>
            <div className="copy">© 2024 - All rights reserved</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
