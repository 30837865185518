import { useTranslation } from "react-i18next";

import Button from "../Button";
import "./index.sass";
import Logo from "./Logo";
import { useEffect, useState } from "react";

interface HeaderProps {
  handlePlay: () => void;
}

const Header = ({ handlePlay }: HeaderProps) => {
  const { i18n } = useTranslation();
  const [showButtons, setShowButtons] = useState(false);

  const handleOnclickLogo = () => window.location.reload();

  const handleOnclick = () => handlePlay();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200 && window.innerWidth > 768) {
        setShowButtons(true);
      } else {
        setShowButtons(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="Header">
      <div className="container">
        <div className="logo" onClick={handleOnclickLogo}>
          <Logo />
        </div>
        <div className={`btns ${showButtons ? "show" : ""}`}>
          <Button onClick={handleOnclick}>{i18n.t("homepage.seccion_1.play_btn")}</Button>
          <Button onClick={handleOnclick} className="secondary">
            {i18n.t("homepage.seccion_1.play_as_guest_btn")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Header;
