import { useTranslation } from "react-i18next";
import "./index.sass";
import "./index.css";
import Button from "../../../components/Button";

interface ISection {
  handlePlay: () => void;
  handlePlayAsAGuest: () => void;
}

const Section = ({ handlePlay, handlePlayAsAGuest }: ISection) => {
  const { i18n } = useTranslation();

  return (
    <div className="StepCenter">
      <div className="body">
        <div className="subbody">
          <div className="global-title">{i18n.t("homepage.seccion_7.title")}</div>
          <div className="global-subtitle subtitle">{i18n.t("homepage.seccion_7.subtitle")}</div>
        </div>
        <div className="btns_group">
          <Button onClick={handlePlay}>{i18n.t("homepage.seccion_1.play_btn")}</Button>
          <Button onClick={handlePlayAsAGuest} className="secondary">
            {i18n.t("homepage.seccion_1.play_as_guest_btn")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Section;
