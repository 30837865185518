import Footer from "../../components/Footer";
import SectionOne from "./Section_1";
import SectionTwo from "./Section_2";
import SectionThree from "./Section_3";
import SectionFour from "./Section_4";

import "./index.sass";

interface IDesktop {
  handlePlay: () => void;
  handlePlayAsAGuest: () => void;
}

const Desktop = ({ handlePlay, handlePlayAsAGuest }: IDesktop) => {
  return (
    <>
      <div className="Homepage">
        <SectionOne handlePlay={handlePlay} handlePlayAsAGuest={handlePlayAsAGuest} />
        <SectionTwo />
        <SectionThree handlePlay={handlePlay} />
        <SectionFour handlePlay={handlePlay} handlePlayAsAGuest={handlePlayAsAGuest} />
        <div className="bgs">
          <img src="/images/bg_1.jpg" alt="" />
          <img src="/images/bg_2.jpg" alt="" />
          <img src="/images/bg_3.jpg" alt="" />
          <img src="/images/bg_4.png" alt="" />
          <Footer />
        </div>
      </div>
    </>
  );
};

export default Desktop;
