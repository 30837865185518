import imageBG from "./coming_soon_bg.jpg";

const ComingSoonPage = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${imageBG})`,
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5%",
        backgroundPosition: "center",
      }}
    >
      <img style={{ width: "100%", maxWidth: "800px", height: "100%", objectFit: "contain" }} src={"/images/coming_soon_text.png"} alt="" />
    </div>
  );
};

export default ComingSoonPage;
