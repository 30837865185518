import ReactGA from "react-ga4";

const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID || "";
const cookieStorageKey = "isp-ithf-cookie";

/**
 * Telemetry helper linked with cookie consent
 * If user gives consent then only intitialise GA
 * Events before cookie consent won't be sent and stored in a queue
 * Once user gives consent it flushes pending queue and enables to send events.
 */
class TelemetryHelper {
  isEnabled: boolean = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendQueue: any[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventQueue: any[] = [];

  init = () => {
    const storeValue = window.localStorage.getItem(cookieStorageKey);
    if (storeValue === "true") {
      this.isEnabled = true;
      this.initGA();
    } else if (storeValue === null || storeValue === undefined) {
      this.openCookieConsent();
    }
  };

  initGA = () => {
    ReactGA.initialize(TRACKING_ID);
    // Timeout as GA initialisation over network varies
    setTimeout(() => {
      this.isEnabled = true;
      // Flush queues
      this.sendQueue.forEach((item) => {
        this.send(item);
      });
      this.eventQueue.forEach((item) => {
        this.event(item);
      });
    }, 3000);
  };

  openCookieConsent = () => {
    if (window.ISP_COOKIE_META) {
      window.ISP_COOKIE_META.onCookieAccept = () => {
        window.localStorage.setItem(cookieStorageKey, "true");
        this.init();
      };
      window.ISP_COOKIE_META.onCookieReject = () => {
        window.localStorage.setItem(cookieStorageKey, "false");
      };
      window.ISP_COOKIE_META?.showCookieModal(true);
    } else
      setTimeout(() => {
        this.openCookieConsent();
      }, 500);
  };

  send: typeof ReactGA.send = (item) => {
    if (this.isEnabled) ReactGA.send(item);
    else this.sendQueue.push(item);
  };

  event: typeof ReactGA.event = (item) => {
    if (this.isEnabled) ReactGA.event(item);
    else this.eventQueue.push(item);
  };
}

export default new TelemetryHelper();
