import "./index.sass";
import "./index.css";
import { useTranslation } from "react-i18next";

const Section = () => {
  const { i18n } = useTranslation();

  return (
    <div className="Section-two-Mob">
      <div className="banner">
        <div className="item item1">
          <div className="subcontainer">
            <div className="title">{i18n.t("homepage.seccion_2.item_1.title")}</div>
            <div className="description">{i18n.t("homepage.seccion_2.item_1.description")}</div>
          </div>
        </div>
        <div className="item item2">
          <div className="subcontainer">
            <div className="title">{i18n.t("homepage.seccion_2.item_2.title")}</div>
            <div className="description">{i18n.t("homepage.seccion_2.item_2.description")}</div>
          </div>
        </div>
        <div className="item item3">
          <div className="subcontainer">
            <div className="title">{i18n.t("homepage.seccion_2.item_3.title")}</div>
            <div className="description">{i18n.t("homepage.seccion_2.item_3.description")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section;
