import "./index.sass";
import "./index.css";
import { useEffect, useState } from "react";

const LoadingPage = () => {
  const [dots, setDots] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((dots) => (dots + 1) % 4);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="LoadingPage">
      <div className="container">
        <div style={{ width: "250px" }}>
          NOW LOADING
          <span className="dots">{Array.from({ length: dots }, (_, i) => ".").join("")}</span>
        </div>
      </div>
    </div>
  );
};

export default LoadingPage;
