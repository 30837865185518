import { useTranslation } from "react-i18next";

import Header from "../../../components/Header";
import Button from "../../../components/Button";

import "./index.sass";

interface ISection {
  handlePlay: () => void;
  handlePlayAsAGuest: () => void;
}

const Section = ({ handlePlay, handlePlayAsAGuest }: ISection) => {
  const { i18n } = useTranslation();

  return (
    <div className="SectionOne">
      <Header handlePlay={handlePlay} />
      <div className="header-margin"></div>
      <div>
        <div className="title">{i18n.t("homepage.seccion_1.title")}</div>
        <div className="subtitle">{i18n.t("homepage.seccion_1.subtitle")}</div>
        <div className="description">{i18n.t("homepage.seccion_1.description")}</div>
        <div className="btns_group">
          <Button onClick={handlePlay}>{i18n.t("homepage.seccion_1.play_btn")}</Button>
          <Button onClick={handlePlayAsAGuest} className="secondary">
            {i18n.t("homepage.seccion_1.play_as_guest_btn")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Section;
